import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import './Layout.scss';
import MainNavigation from './MainNavigation';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../store/store';
const Layout: React.FC<{ children: React.ReactNode }> = (props) => {
  //const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { pathname } = useLocation();

  return (
    <div className='wrapper'>
      {(pathname.includes('/privacy-app') ||
        pathname.includes('/terms-app') ||
        pathname.includes('/about-app')) && (
        <div className='main-app'>{props.children}</div>
      )}
      {!pathname.includes('/privacy-app') &&
        !pathname.includes('/terms-app') &&
        !pathname.includes('/about-app') && (
          <>
            <MainNavigation />
            <main className='main'>
              <div className='main_content'>{props.children}</div>
            </main>
            <Footer />
          </>
        )}
    </div>
  );
};
export default Layout;
