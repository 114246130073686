import * as React from 'react';
import Rating from '@mui/material/Rating';
import {
  getAmountWithCurrency,
  getNameAvatar,
  getRatingLabel,
  maskString,
} from '../../utils/functions';
import './ReviewListItem.scss';
import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';
import moment from 'moment';

const ReviewListItem: React.FC<{
  _id: string;
  createdAt: string;
  rating: number;
  message: string;
  review_by: {
    name: string;
    total_earnings: number;
  };
}> = ({
  _id,
  createdAt,
  rating,
  message,
  review_by: { name, total_earnings },
}) => {
  return (
    <div className='reviews_cards_item'>
      <div className='review_item_details'>
        <div className='title_logo'>
          <div className='logo_comp'>
            <Avatar
              sx={{
                bgcolor: Colors.pclXLight,
                marginRight: 1,
                color: Colors.primaryColorLight,
                boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
              }}>
              {getNameAvatar(name)}
            </Avatar>
            <div className='logo_comp_star'>
              <p className='business_name'>{maskString(name)}</p>
              <p className='business_id'>
                মোট উপার্জনঃ {getAmountWithCurrency(total_earnings)}
              </p>
            </div>
          </div>
        </div>
        <div className='content_wrapper'>
          <p className='content_text'>{message}</p>
        </div>
      </div>

      <div className='rating_wrapper'>
        <div className='rating_star'>
          <p style={{ marginRight: 5 }}>{rating?.toFixed(1)}</p>
          <Rating
            name='simple-controlled'
            value={rating}
            size='small'
            onChange={(event, newValue) => {
              //
            }}
          />

          <div className='rating_status'>
            <p>{getRatingLabel(rating)}</p>
          </div>
        </div>

        <div className='rating_date'>
          <p>{moment(createdAt).format('MMM DD, YYYY')}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewListItem;
