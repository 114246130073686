import React from 'react';

const TermsPage: React.FC<{}> = (props) => {
  return (
    <>
      <h2>
        <strong> টার্মস এবং কন্ডিশন </strong>
      </h2>

      <br />

      <p>মোবাইল দ্বারা উপার্জনে স্বাগতম!</p>

      <p>
        এই শর্তাবলী ব্যবহারের জন্য নিয়ম এবং প্রবিধান রূপরেখা Earn By Mobile এর
        ওয়েবসাইট, earnbymobile.site এ অবস্থিত।
      </p>

      <p>
        এই ওয়েবসাইট অ্যাক্সেস করে আমরা ধরে নিই যে আপনি এই শর্তাবলী স্বীকার করেন
        এবং শর্তাবলী আপনি সম্মত না হলে মোবাইল দ্বারা উপার্জন করা চালিয়ে যাবেন
        না এই পৃষ্ঠায় উল্লিখিত সমস্ত শর্তাবলী গ্রহণ করুন।
      </p>

      <br />

      <p>
        নিম্নলিখিত পরিভাষা এই শর্তাবলী প্রযোজ্য, গোপনীয়তা বিবৃতি এবং দাবিত্যাগ
        বিজ্ঞপ্তি এবং সমস্ত চুক্তি: "ক্লায়েন্ট", "আপনি" এবং "আপনার" আপনাকে
        বোঝায়, যে ব্যক্তি এই ওয়েবসাইটে লগ ইন করে এবং অনুগত কোম্পানির শর্তাবলী।
        "কোম্পানি", "আমরা", "আমরা", "আমাদের" এবং "আমাদের", আমাদের কোম্পানিকে
        বোঝায়। "পার্টি", "পার্টি" বা "আমাদের", ক্লায়েন্ট এবং নিজেদের উভয়কেই
        বোঝায়। সমস্ত শর্ত অফার উল্লেখ করে, গ্রহণ করার জন্য প্রয়োজনীয় অর্থ
        প্রদানের গ্রহণযোগ্যতা এবং বিবেচনা সবচেয়ে উপযুক্ত পদ্ধতিতে ক্লায়েন্টকে
        আমাদের সহায়তার প্রক্রিয়া সাপেক্ষে ক্লায়েন্টের চাহিদা মেটানোর স্পষ্ট
        উদ্দেশ্যে কোম্পানির বিবৃত পরিষেবার বিধান, অনুযায়ী এবং সাপেক্ষে, gb এর
        প্রচলিত আইন। উপরের পরিভাষার কোন ব্যবহার বা একবচনে অন্যান্য শব্দ, বহুবচন,
        ক্যাপিটালাইজেশন এবং/অথবা সে/সে বা তারা, বিনিময়যোগ্য হিসাবে গ্রহণ করা
        হয় এবং তাই একই উল্লেখ হিসাবে।
      </p>

      <br />
      <br />
      <h3>
        <strong>কুকিজ</strong>
      </h3>

      <p>
        আমরা কুকিজ ব্যবহার নিযুক্ত. Earn By Mobile অ্যাক্সেস করে, আপনি সম্মত
        হয়েছেন Earn By Mobile এর গোপনীয়তা নীতির সাথে চুক্তিতে কুকি ব্যবহার
        করুন৷
      </p>

      <p>
        বেশিরভাগ ইন্টারেক্টিভ ওয়েবসাইট আমাদের ব্যবহারকারীর পুনরুদ্ধার করতে
        কুকিজ ব্যবহার করে প্রতিটি দর্শনের জন্য বিস্তারিত। কুকিজ সক্রিয় করতে
        আমাদের ওয়েবসাইট দ্বারা ব্যবহার করা হয় আমাদের পরিদর্শন করা লোকেদের জন্য
        এটি সহজ করার জন্য নির্দিষ্ট এলাকার কার্যকারিতা ওয়েবসাইট আমাদের কিছু
        অনুমোদিত/বিজ্ঞাপন অংশীদাররাও ব্যবহার করতে পারে কুকিজ
      </p>

      <br />
      <br />

      <h3>
        <strong>লাইসেন্স</strong>
      </h3>

      <p>
        অন্যথায় বলা না থাকলে, মোবাইলের মাধ্যমে উপার্জন করুন এবং/অথবা এর
        লাইসেন্সধারীরা এর মালিক Earn By Mobile-এ সমস্ত উপাদানের জন্য বৌদ্ধিক
        সম্পত্তির অধিকার। সব বুদ্ধিবৃত্তিক সম্পত্তি অধিকার সংরক্ষিত। আপনি Earn
        থেকে এটি অ্যাক্সেস করতে পারেন আপনার নিজের ব্যক্তিগত ব্যবহারের জন্য
        মোবাইল দ্বারা সেট করা বিধিনিষেধ সাপেক্ষে এই শর্তাবলী
      </p>

      <br />
      <p>আপনি অবশ্যই করবেন না:</p>
      <ul>
        <li>আর্ন বাই মোবাইল থেকে উপাদান পুনঃপ্রকাশ করুন</li>
        <li>আর্ন বাই মোবাইল থেকে বিক্রয়, ভাড়া বা সাব-লাইসেন্স সামগ্রী</li>
        <li>
          আর্ন বাই মোবাইল থেকে উপাদান পুনরুত্পাদন, অনুলিপি বা অনুলিপি করুন
        </li>
        <li>আর্ন বাই মোবাইল থেকে সামগ্রী পুনরায় বিতরণ করুন</li>
      </ul>

      <p>
        এই চুক্তির তারিখ থেকে শুরু হবে। আমাদের শর্তাবলী এর সাহায্যে তৈরি করা
        হয়েছিল
        <a href='https://www.termsandconditionsgenerator.com/'>
          বিনামূল্যে শর্তাবলী জেনারেটর
        </a>
        .
      </p>

      <p>
        এই ওয়েবসাইটের অংশগুলি ব্যবহারকারীদের পোস্ট করার সুযোগ দেয় এবং
        ওয়েবসাইটের নির্দিষ্ট এলাকায় মতামত এবং তথ্য বিনিময়. সম্পূর্ণরূপে বুক
        করা ফিল্টার না, সম্পাদনা, প্রকাশ বা প্রশংসাসূচক মন্তব্য আগে ওয়েবসাইটে
        তাদের উপস্থিতি। মন্তব্য মতামত এবং প্রতিফলিত না মোবাইলের মাধ্যমে উপার্জন
        করুন, এর এজেন্ট এবং/অথবা সহযোগীদের মতামত। মন্তব্য যে ব্যক্তি তাদের মতামত
        পোস্ট করেন তার মতামত এবং মতামত প্রতিফলিত করে এবং মতামত প্রযোজ্য আইন
        দ্বারা অনুমোদিত পরিমাণে, মোবাইল দ্বারা উপার্জন করুন৷ মন্তব্যের জন্য বা
        কোন দায়, ক্ষতি বা জন্য দায়ী করা হবে না কোনো ব্যবহার এবং/অথবা পোস্টিং
        এর ফলে সৃষ্ট এবং/অথবা ভোগান্তির খরচ এই ওয়েবসাইটে মন্তব্যের এবং/অথবা
        উপস্থিতি।
      </p>

      <br />
      <br />

      <p>
        মোবাইল দ্বারা উপার্জন করুন সমস্ত মন্তব্য নিরীক্ষণ এবং সরানোর অধিকার
        সংরক্ষণ করে কোনো মন্তব্য যা অনুপযুক্ত, আপত্তিকর বা কারণ হিসেবে বিবেচিত
        হতে পারে এই শর্তাবলী লঙ্ঘন.
      </p>

      <p>আপনি ওয়ারেন্ট এবং প্রতিনিধিত্ব করেন যে:</p>

      <ul>
        <li>
          আপনি আমাদের ওয়েবসাইটে মন্তব্য পোস্ট করার অধিকারী এবং সব আছে এটি করার
          জন্য প্রয়োজনীয় লাইসেন্স এবং সম্মতি;
        </li>
        <li>
          মন্তব্যগুলি সহ কোনও বৌদ্ধিক সম্পত্তির অধিকারকে আক্রমণ করে না কোনো
          তৃতীয় পক্ষের কপিরাইট, পেটেন্ট বা ট্রেডমার্ক সীমাবদ্ধতা ছাড়াই;
        </li>
        <li>
          মন্তব্যে কোনো মানহানিকর, মানহানিকর, আপত্তিকর, অশ্লীল বা অন্যথায়
          বেআইনি উপাদান যা একটি আক্রমণ গোপনীয়তা
        </li>
        <li>
          মন্তব্য অনুরোধ বা ব্যবসা বা কাস্টম প্রচার করতে ব্যবহার করা হবে না বা
          বাণিজ্যিক কার্যক্রম বা বেআইনি কার্যকলাপ উপস্থাপন করুন।
        </li>
      </ul>

      <br />
      <p>
        আপনি এতদ্বারা Earn By Mobile ব্যবহার করার জন্য একটি নন-এক্সক্লুসিভ
        লাইসেন্স প্রদান করেন, অন্যদের ব্যবহার, পুনরুত্পাদন এবং সম্পাদনা করার
        জন্য পুনরুত্পাদন, সম্পাদনা এবং অনুমোদন করুন আপনার মন্তব্য যেকোন এবং
        সমস্ত ফর্ম, ফর্ম্যাট বা মিডিয়াতে।
      </p>

      <br />
      <br />
      <h3>
        <strong>আমাদের সামগ্রীতে হাইপারলিঙ্ক করা</strong>৷
      </h3>

      <p>
        নিম্নলিখিত সংস্থাগুলি পূর্বে ছাড়াই আমাদের ওয়েবসাইটে লিঙ্ক করতে পারে
        লিখিত অনুমোদন:
      </p>

      <ul>
        <li>সরকারি সংস্থাগুলি;</li>
        <li>সার্চ ইঞ্জিন;</li>
        <li>সংবাদ সংস্থা;</li>
        <li>
          অনলাইন ডিরেক্টরি ডিস্ট্রিবিউটররা আমাদের ওয়েবসাইটের সাথে লিঙ্ক করতে
          পারে যেভাবে তারা অন্যান্য তালিকাভুক্ত ব্যবসার ওয়েবসাইটে হাইপারলিঙ্ক
          করে; এবং
        </li>
        <li>
          অলাভজনক অনুরোধ ছাড়া সিস্টেম ওয়াইড স্বীকৃত ব্যবসা সংগঠন, দাতব্য শপিং
          মল এবং দাতব্য তহবিল সংগ্রহকারী গোষ্ঠী যা আমাদের ওয়েব সাইটে হাইপারলিংক
          নাও হতে পারে।
        </li>
      </ul>

      <p>
        এই সংস্থাগুলি আমাদের হোম পেজ, প্রকাশনা বা এর সাথে লিঙ্ক করতে পারে
        অন্যান্য ওয়েবসাইটের তথ্য এতদিন লিংক: (ক) কোনোভাবেই নয় বিভ্রান্তিকর;
        (b) মিথ্যাভাবে স্পনসরশিপ, অনুমোদন বা বোঝায় না লিঙ্কিং পার্টি এবং এর
        পণ্য এবং/অথবা পরিষেবাগুলির অনুমোদন; এবং (গ) লিঙ্কিং পার্টির সাইটের
        প্রসঙ্গের মধ্যে ফিট করে।
      </p>

      <br />

      <p>
        আমরা নিম্নলিখিত ধরনের থেকে অন্যান্য লিঙ্ক অনুরোধ বিবেচনা এবং অনুমোদন
        করতে পারি সংস্থাগুলির:
      </p>

      <ul>
        <li>সাধারণভাবে পরিচিত ভোক্তা এবং/অথবা ব্যবসায়িক তথ্যের উৎস;</li>
        <li>dot.com কমিউনিটি সাইট;</li>
        <li>
          অ্যাসোসিয়েশন বা দাতব্য সংস্থার প্রতিনিধিত্বকারী অন্যান্য গোষ্ঠী;
        </li>
        <li>অনলাইন ডিরেক্টরি ডিস্ট্রিবিউটর;</li>
        <li>ইন্টারনেট পোর্টাল;</li>
        <li>অ্যাকাউন্টিং, আইন এবং পরামর্শকারী সংস্থাগুলি; এবং</li>
        <li>শিক্ষা প্রতিষ্ঠান এবং ট্রেড অ্যাসোসিয়েশন।</li>
      </ul>

      <p>
        আমরা সিদ্ধান্ত নিলে এই সংস্থাগুলি থেকে লিঙ্কের অনুরোধগুলি অনুমোদন করব৷
        যে: (ক) লিঙ্কটি আমাদের নিজেদের প্রতি বা প্রতিকূলভাবে দেখাবে না আমাদের
        স্বীকৃত ব্যবসা; (খ) সংস্থার নেই আমাদের সাথে নেতিবাচক রেকর্ড; (c) এর
        দৃশ্যমানতা থেকে আমাদের সুবিধা হাইপারলিঙ্ক মোবাইল দ্বারা উপার্জনের
        অনুপস্থিতিকে ক্ষতিপূরণ দেয়; এবং (ঘ) দ লিঙ্কটি সাধারণ সম্পদ তথ্যের
        প্রসঙ্গে।
      </p>

      <br />
      <br />

      <p>
        এই সংস্থাগুলি আমাদের হোম পেজে লিঙ্ক করতে পারে যতক্ষণ না লিঙ্কটি থাকে:
        (ক) কোনোভাবেই প্রতারণামূলক নয়; (b) মিথ্যাভাবে স্পনসরশিপ বোঝায় না,
        লিঙ্কিং পার্টি এবং এর পণ্যগুলির অনুমোদন বা অনুমোদন বা সেবা; এবং (গ)
        লিঙ্কিং পার্টির সাইটের প্রেক্ষাপটের মধ্যে ফিট করে।
      </p>

      <p>
        আপনি যদি উপরের অনুচ্ছেদ 2 এ তালিকাভুক্ত সংস্থাগুলির মধ্যে একজন হন এবং হন
        আমাদের ওয়েবসাইটে লিঙ্ক করতে আগ্রহী, আপনাকে অবশ্যই একটি পাঠিয়ে আমাদের
        জানাতে হবে মোবাইলে আয় করতে ই-মেইল করুন। আপনার নাম, আপনার প্রতিষ্ঠান
        অন্তর্ভুক্ত করুন নাম, যোগাযোগের তথ্যের পাশাপাশি আপনার সাইটের URL, যেকোনো
        একটি তালিকা যে ইউআরএলগুলি থেকে আপনি আমাদের ওয়েবসাইটে লিঙ্ক করতে চান এবং
        এর একটি তালিকা আপনি লিঙ্ক করতে চান যে আমাদের সাইটের URL. একটি জন্য 2-3
        সপ্তাহ অপেক্ষা করুন প্রতিক্রিয়া
      </p>

      <br />
      <br />
      <p>
        অনুমোদিত প্রতিষ্ঠানগুলি নিম্নরূপ আমাদের ওয়েবসাইটে হাইপারলিঙ্ক করতে
        পারে:
      </p>

      <ul>
        <li>আমাদের কর্পোরেট নাম ব্যবহার করে; অথবা</li>
        <li> ইউনিফর্ম রিসোর্স লোকেটার ব্যবহার করে লিঙ্ক করা হচ্ছে; অথবা</li>
        <li>
          আমাদের ওয়েবসাইট অন্য কোনো বর্ণনা ব্যবহার করে যে লিঙ্ক করা হচ্ছে
          লিঙ্কিং এর বিষয়বস্তুর প্রেক্ষাপট এবং বিন্যাসের মধ্যে বোঝা যায়
          পার্টির সাইট।
        </li>
      </ul>

      <p>
        Earn By Mobile এর লোগো বা অন্য কোন আর্টওয়ার্ক ব্যবহারের অনুমতি দেওয়া
        হবে না একটি ট্রেডমার্ক লাইসেন্স চুক্তি অনুপস্থিত লিঙ্ক.
      </p>

      <br />
      <br />
      <h3>
        <strong>iFrames</strong>
      </h3>

      <p>
        পূর্ব অনুমোদন এবং লিখিত অনুমতি ছাড়া, আপনি ফ্রেম তৈরি করতে পারবেন না
        আমাদের ওয়েবপৃষ্ঠাগুলির আশেপাশে যা ভিজ্যুয়াল উপস্থাপনা বা আমাদের
        ওয়েবসাইটের উপস্থিতি।
      </p>

      <br />
      <br />
      <h3>
        <strong>সামগ্রীর দায়</strong>
      </h3>

      <p>
        আমরা আপনার উপর প্রদর্শিত কোনো বিষয়বস্তুর জন্য দায়ী করা হবে না
        ওয়েবসাইট। আপনি যে সমস্ত দাবির বিরুদ্ধে আমাদের রক্ষা করতে এবং রক্ষা করতে
        সম্মত হন আপনার ওয়েবসাইটে ক্রমবর্ধমান. কোনো লিঙ্ক(গুলি) কোনো ওয়েবসাইটে
        প্রদর্শিত হবে না যা হতে পারে মানহানিকর, অশ্লীল বা অপরাধী হিসাবে ব্যাখ্যা
        করা হবে বা যা লঙ্ঘন করে, অন্যথায় লঙ্ঘন করে, বা লঙ্ঘন বা অন্য লঙ্ঘনের
        সমর্থন করে, কোনো তৃতীয় পক্ষের অধিকার।
      </p>

      <br />
      <br />
      <h3>
        <strong>অধিকার সংরক্ষণ</strong>
      </h3>

      <p>
        আমরা অনুরোধ করার অধিকার সংরক্ষণ করি যে আপনি সমস্ত লিঙ্ক বা যেকোনও সরান
        আমাদের ওয়েবসাইটের বিশেষ লিঙ্ক। আপনি অবিলম্বে সব অপসারণ অনুমোদন অনুরোধের
        ভিত্তিতে আমাদের ওয়েবসাইটের লিঙ্ক। আমরা আমীন করার অধিকারও সংরক্ষণ করি এই
        শর্তাবলী এবং এটি যে কোনো সময় লিঙ্কিং নীতি। দ্বারা আমাদের ওয়েবসাইটে
        ক্রমাগত লিঙ্ক করা, আপনি আবদ্ধ হতে এবং অনুসরণ করতে সম্মত হন এই লিঙ্কিং
        শর্তাবলী।
      </p>

      <br />
      <br />
      <h3>
        <strong>আমাদের ওয়েবসাইট থেকে লিঙ্ক অপসারণ</strong>
      </h3>

      <p>
        আপনি যদি আমাদের ওয়েবসাইটে এমন কোনো লিঙ্ক খুঁজে পান যা কোনো কারণে
        আপত্তিকর, আপনি যেকোনো মুহূর্তে আমাদের সাথে যোগাযোগ করতে এবং জানাতে
        মুক্ত। আমরা বিবেচনা করব লিঙ্কগুলি সরানোর জন্য অনুরোধ করা হয়েছে কিন্তু
        আমরা বা তার জন্য বা প্রতিক্রিয়া জানাতে বাধ্য নই সরাসরি আপনার কাছে।
      </p>

      <p>
        আমরা নিশ্চিত করি না যে এই ওয়েবসাইটের তথ্য সঠিক, আমরা করি এর সম্পূর্ণতা
        বা নির্ভুলতার নিশ্চয়তা দেয় না; বা আমরা নিশ্চিত করার প্রতিশ্রুতি দিই না
        যে ওয়েবসাইটটি উপলভ্য থাকে বা ওয়েবসাইটের উপাদান আপ টু ডেট রাখা হয়।
      </p>

      <br />
      <br />
      <h3>
        <strong>অস্বীকৃতি</strong>
      </h3>

      <p>
        প্রযোজ্য আইন দ্বারা অনুমোদিত সর্বাধিক পরিমাণে, আমরা সব বাদ দিই
        উপস্থাপনা, ওয়ারেন্টি এবং আমাদের ওয়েবসাইট সম্পর্কিত শর্তাবলী এবং এই
        ওয়েবসাইট ব্যবহার. এই দাবিত্যাগের কিছুই হবে না:
      </p>

      <ul>
        <li>
          মৃত্যু বা ব্যক্তিগত আঘাতের জন্য আমাদের বা আপনার দায়বদ্ধতা সীমিত বা
          বাদ দিন;
        </li>
        <li>
          প্রতারণা বা প্রতারণার জন্য আমাদের বা আপনার দায়বদ্ধতা সীমাবদ্ধ বা বাদ
          দিন ভুল উপস্থাপনা;
        </li>
        <li>
          আমাদের বা আপনার কোন দায়বদ্ধতাকে সীমিত করা যে কোন উপায়ে অনুমোদিত নয়
          প্রযোজ্য আইনের অধীনে; বা
        </li>
        <li>
          আমাদের বা আপনার দায়গুলির যে কোনও বাদ দিন যা বাদ দেওয়া যাবে না
          প্রযোজ্য আইন.
        </li>
      </ul>

      <br />
      <p>
        দায়বদ্ধতার সীমাবদ্ধতা এবং নিষেধাজ্ঞাগুলি এই বিভাগে সেট করা হয়েছে এবং
        এই দাবিত্যাগের অন্যত্র: (ক) পূর্ববর্তী সাপেক্ষে অনুচ্ছেদ; এবং (খ)
        দাবিত্যাগের অধীনে উদ্ভূত সমস্ত দায় নিয়ন্ত্রণ করে, চুক্তিতে উদ্ভূত দায়
        সহ, টর্ট এবং লঙ্ঘনের জন্য বিধিবদ্ধ কর্তব্য।
      </p>

      <br />
      <p>
        যতক্ষণ ওয়েবসাইট এবং ওয়েবসাইটের তথ্য ও সেবা বিনামূল্যে প্রদান করা হয়,
        আমরা কোনো ক্ষতি বা জন্য দায়ী করা হবে না যে কোন প্রকৃতির ক্ষতি।
      </p>
    </>
  );
};

export default TermsPage;
