import LoadingSpinner from '../../components/UI/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import ReviewListItem from '../../components/ListItem/ReviewListItem';
import CustomButton from '../../components/UI/CustomButton';
import './ReviewsPage.scss';
import Rating from '@mui/material/Rating';
import { useLocation, useNavigate } from 'react-router-dom';

const GET_ALL_TESTIMONIALS = gql`
  query GetAllTestimonialsByPage($input: GetTestimonialsByPageInput!) {
    getAllTestimonialsByPage(input: $input) {
      _id
      message
      isVisible
      rating
      createdAt
      added_by {
        name
        total_earnings
      }
    }
  }
`;

const GET_TESTIMONIAL_BY_PAGE_STATS = gql`
  query GetAllTestimonialsByPageStats {
    getAllTestimonialsByPageStats {
      _id
      averageRating
      numRating
    }
  }
`;

const ReviewsPage = () => {
  const [page_number, setPage_number] = useState(1);
  const { search } = useLocation();
  const navigate = useNavigate();

  const {
    data: testimonials_data,
    error: testimonials_dataError,
    loading: testimonials_dataLoading,
    refetch: testimonials_dataRefectch,
  } = useQuery(GET_ALL_TESTIMONIALS, {
    variables: {
      input: {
        page: page_number,
        item_per_page: 21,
      },
    },
  });

  const reviews = testimonials_data?.getAllTestimonialsByPage || [];

  const {
    data: testimonial_data_stats,
    error: testimonial_data_statsError,
    loading: testimonial_data_statsLoading,
    refetch: testimonial_data_statsRefectch,
  } = useQuery(GET_TESTIMONIAL_BY_PAGE_STATS);

  const testimonialStats =
    testimonial_data_stats?.getAllTestimonialsByPageStats;

  return (
    <>
      <div className='reviews_header'>
        <div className='reviews_header-filter'></div>

        {testimonialStats && (
          <div className='reviews_header-average'>
            <p style={{ marginRight: 10 }}>
              এভারেজ রেটিং {testimonialStats?.averageRating.toFixed(2)}
            </p>
            <Rating
              name='simple-controlled'
              value={testimonialStats?.averageRating}
              precision={0.1}
              disabled={true}
              size='small'
              onChange={(event, newValue) => {
                //
              }}
            />

            <div className='rating_status'>
              <p>({testimonialStats?.numRating})</p>
            </div>
          </div>
        )}
      </div>
      <div className='reviews_cards'>
        {testimonials_dataLoading && !reviews && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <LoadingSpinner />
          </div>
        )}

        {reviews?.length === 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '100px',
            }}>
            <p>কোনো রিভিউ পাওয়া যায়নি</p>
          </div>
        )}

        {reviews?.map((rv: any) => {
          return (
            <ReviewListItem
              _id={rv._id}
              key={rv._id}
              createdAt={rv.createdAt}
              rating={rv.rating}
              message={rv.message}
              review_by={{
                name: rv.added_by.name,
                total_earnings: rv.added_by.total_earnings,
              }}
            />
          );
        })}
      </div>

      <div className='reviews_action_wrapper'>
        <CustomButton
          title='আরো লোড করুন'
          onClick={() => {
            setPage_number((pn) => pn + 1);
            testimonials_dataRefectch();
          }}
          isLoading={testimonials_dataLoading}
        />
      </div>

      <div className='footer_content'></div>
    </>
  );
};

export default ReviewsPage;
