export default {
  // Light Colors
  primaryColorLight: '#F91880',
  pclXLight: '#F5B3D6',
  secondaryColorLight: '#67ae11',
  accentColorLight: '#f94b18',
  light: '#ffffff',
  lightBg: '#F3F8FF',
  footerBg: '#0C4A60',

  // Dark Colors
  primaryColorDark: '#93f918',
  pcdXLight: '#D6F7B7',
  secondaryColorDark: '#67ae11',
  accentColorDark: '#ae3511',
  dark: '#000000',

  //Common Colors
  grey: '#F3F3F3',
  lightGrey: '#C9C9C9',
  darkGrey: '#C1C7D0',
  darkerGrey: '#747474',
  fontColor1: '#93A1AC',
  fontColor2: '#324A59',
};
