import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  isAuthenticated: boolean;
  target: {
    _id: string;
    target_name: string;
    target_phone: string;
    referred_by?: string;
  };
}

const initialState: AuthState = {
  isAuthenticated: false,
  target: {
    _id: '',
    target_name: '',
    target_phone: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate: (
      state,
      data: PayloadAction<{
        isAuthenticated: boolean;
        target: {
          _id: string;
          target_name: string;
          target_phone: string;
          referred_by?: string;
        };
      }>
    ) => {
      state.isAuthenticated = data.payload.isAuthenticated;
      state.target = data.payload.target;
    },

    resetAuth: (state) => {
      state.isAuthenticated = false;
      state.target = initialState.target;
    },
  },
});

// Action creators are generated for each case reducer function
export const { authenticate, resetAuth } = authSlice.actions;

export default authSlice.reducer;
