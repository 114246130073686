import React, { Suspense } from 'react';
import Layout from './components/Layout/Layout';
import LoadingSpinner from './components/UI/LoadingSpinner';
// import { useSelector } from 'react-redux';
// import { RootState } from './store/store';
import { Route, Routes } from 'react-router-dom';
import ErrorPage from './pages/error/ErrorPage';
import HomePage from './pages/home/HomePage';
import PrivacyPageApp from './pages/privacy/PrivacyPageApp';
import TermsPageApp from './pages/terms/TermsPageApp';
import AboutUsPage from './pages/about/AboutUsPage';
import PrivacyPage from './pages/privacy/PrivacyPage';
import TermsPage from './pages/terms/TermsPage';
import AboutUsPageApp from './pages/about/AboutUsPageApp';
import AdminSupportPage from './pages/support/AdminSupportPage';
import ReviewsPage from './pages/reviews/ReviewsPage';
import HowToEarnPage from './pages/howToEarn/HowToEarnPage';

const App = () => {
  //const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  return (
    <Layout>
      <Suspense
        fallback={
          <div className='centered'>
            <LoadingSpinner />
          </div>
        }>
        <Routes>
          <Route path='/'>
            <Route index={true} element={<HomePage />} />
          </Route>

          <Route path='reviews'>
            <Route index={true} element={<ReviewsPage />} />
          </Route>

          <Route path='how-to-earn'>
            <Route index={true} element={<HowToEarnPage />} />
          </Route>

          <Route path='admin-support'>
            <Route index={true} element={<AdminSupportPage />} />
          </Route>

          <Route path='about-us'>
            <Route index={true} element={<AboutUsPage />} />
          </Route>

          <Route path='privacy-policy'>
            <Route index={true} element={<PrivacyPage />} />
          </Route>

          <Route path='terms-conditions'>
            <Route index={true} element={<TermsPage />} />
          </Route>

          <Route path='/privacy-app' element={<PrivacyPageApp />} />
          <Route path='/terms-app' element={<TermsPageApp />} />
          <Route path='/about-app' element={<AboutUsPageApp />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;
