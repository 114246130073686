import React, { useEffect, useRef, useState } from 'react';
import './AdminSupportPage.scss';
import { Avatar, CircularProgress, IconButton } from '@mui/material';
import Colors from '../../constants/Colors';
import { formatMessages, getNameAvatar } from '../../utils/functions';
import SendIcon from '@mui/icons-material/Send';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { motion } from 'framer-motion';
import moment from 'moment';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import WhatsApp from '@mui/icons-material/WhatsApp';

const ADD_SUPPORT = gql`
  mutation AddSupport($input: AddSupportInput!) {
    addSupport(input: $input) {
      _id
      target {
        _id
      }
      conversations {
        sender
        content
        sentAt
      }
    }
  }
`;

const UPDATE_SUPPORT = gql`
  mutation UpdateSupport($input: UpdateSupportInput!) {
    updateSupport(input: $input) {
      _id
      target {
        _id
      }
      conversations {
        sender
        content
        sentAt
      }
    }
  }
`;

const GET_SUPPORT_BY_TARGET_ID = gql`
  query GetSupportByTargetId($input: MongoIdInput!) {
    getSupportByTargetId(input: $input) {
      _id
      target {
        _id
        target_name
      }
      conversations {
        _id
        sender
        content
        sentAt
      }
    }
  }
`;

const AdminSupportPage: React.FC<{}> = (props) => {
  const { target, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const cuRef = useRef<HTMLDivElement>(null);
  const [tMessage, setTMessage] = useState('');

  const [addTargetMessage, addTargetMessageData] = useMutation(ADD_SUPPORT);

  const [updateTargetMessage, updateTargetMessageData] =
    useMutation(UPDATE_SUPPORT);

  const {
    data: target_message_data,
    error: target_message_dataError,
    loading: target_message_dataLoading,
    refetch: target_message_dataRefectch,
  } = useQuery(GET_SUPPORT_BY_TARGET_ID, {
    variables: {
      input: {
        _id: target?._id || '64f8267c7ed1e6aa0166bde1', // RANDOM ID
      },
    },
  });

  const target_message = target_message_data?.getSupportByTargetId;
  const conversations = target_message?.conversations || [];

  const formattedMessages = formatMessages(conversations);

  useEffect(() => {
    const itv = setInterval(() => {
      target_message_dataRefectch();
    }, 5000);

    return () => clearInterval(itv);
  }, []);

  useEffect(() => {
    cuRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [conversations.length]);

  return (
    <>
      <div className='admin_support'>
        {!isAuthenticated && (
          <div
            style={{
              width: '90%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 200,
              padding: 20,
            }}>
            <p style={{ textAlign: 'center', fontSize: 18 }}>
              এই মুহুর্তে আপনি সাপোর্ট মেসেজ এক্সেস করতে পারবেন না। আপনি এই ওয়েব
              পেজের নিচের দিকে যে ওয়াটসএপ নাম্বার দেওয়া আছে সেখানে মেসেজ করুন।
            </p>
          </div>
        )}

        {isAuthenticated && (
          <>
            <div className='admin_support-header'>
              <div
                className='admin_support-header_logo'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  minWidth: 50,
                  marginRight: 15,
                }}>
                <Avatar
                  src='/images/logo_white.png'
                  sx={{
                    width: 30,
                    height: 30,
                    marginRight: 1,
                    color: Colors.primaryColorLight,
                    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                  }}
                />
                <p>এডমিন</p>
              </div>

              <div style={{}} className='admin_support-header_cu'>
                <div
                  className='cu_phone'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <WhatsApp style={{ color: '#fff', marginRight: 5 }} />
                  <p>+44 7915610027</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <EmailOutlinedIcon
                    style={{ color: '#fff', marginRight: 5 }}
                  />
                  <p>earnbymobile.uk@gmail.com</p>
                </div>
              </div>
            </div>
            <div className='admin_support-body'>
              <div className='admin_support-body--h'>
                <p>
                  {target_message === null
                    ? 'কনভারসেশন শুরু করার জন্য একটি মেসেজ দিন'
                    : `আপনি এডমিনের সাথে কথা বলছেন`}
                </p>
              </div>
              <div className='admin_support-body--c'>
                {/* ITIRATE HERE */}
                {/* DATE */}

                {target_message && (
                  <>
                    {formattedMessages.map((m) => {
                      if (typeof m === 'string') {
                        return (
                          <div key={m} className='admin_support-body--c_date'>
                            <p>{m}</p>
                          </div>
                        );
                      } else if (m.sender === 'target') {
                        return (
                          <motion.div
                            key={m._id}
                            // initial={{ y: 10, opacity: 0 }}
                            // animate={{ y: 0, opacity: 1 }}
                            // transition={{ duration: 0.5 }}
                            className='admin_support-body--c_right'>
                            <div className='conv_message'>
                              <pre className='message_content'>{m.content}</pre>
                            </div>
                            <div className='conv_time'>
                              <p>{moment(m.sentAt).format('HH:mm')}</p>
                            </div>
                          </motion.div>
                        );
                      } else {
                        return (
                          <motion.div
                            key={m._id}
                            // initial={{ y: 10, opacity: 0 }}
                            // animate={{ y: 0, opacity: 1 }}
                            // transition={{ duration: 0.5 }}
                            className='admin_support-body--c_left'>
                            <div className='conv_message'>
                              <Avatar
                                sx={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 1,
                                  bgcolor: Colors.pclXLight,
                                  color: Colors.primaryColorLight,
                                  boxShadow:
                                    '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                }}
                              />
                              <pre className='message_content'>{m.content}</pre>
                            </div>
                            <div className='conv_time'>
                              <p>{moment(m.sentAt).format('HH:mm')}</p>
                            </div>
                          </motion.div>
                        );
                      }
                    })}
                  </>
                )}
                <div ref={cuRef} />
              </div>
              <div className='admin_support-body--i'>
                <div className='admin_support-body--i_inp'>
                  <textarea
                    name=''
                    id=''
                    placeholder='নতুন মেসেজ টাইপ করুন...'
                    value={tMessage}
                    onChange={(e) => {
                      setTMessage(e.target.value);
                    }}
                    cols={20}
                    rows={2}></textarea>
                </div>
                <div className='admin_support-body--i_sub'>
                  <IconButton
                    onClick={async () => {
                      // Send BroadCast Message
                      setIsSubmitting(true);
                      try {
                        if (target_message === null && target._id) {
                          await addTargetMessage({
                            variables: {
                              input: {
                                target: target._id,
                                conversations: {
                                  sender: 'target',
                                  content: tMessage,
                                },
                              },
                            },
                          });
                        }

                        if (target_message) {
                          await updateTargetMessage({
                            variables: {
                              input: {
                                _id: target_message._id,
                                conversations: {
                                  sender: 'target',
                                  content: tMessage,
                                },
                              },
                            },
                          });
                        }

                        setTMessage('');
                        setIsSubmitting(false);

                        setTimeout(() => {
                          target_message_dataRefectch();
                        }, 1000);
                      } catch (err) {
                        setIsSubmitting(false);
                        console.log(err);
                      }
                    }}
                    disabled={!(tMessage.trim().length >= 2)}
                    style={{ color: '#fff', backgroundColor: '#0C4A60' }}>
                    {isSubmitting ? (
                      <CircularProgress style={{ color: '#fff' }} size={24} />
                    ) : (
                      <SendIcon
                        style={{
                          marginLeft: 3,
                          transform: 'rotate(-45deg)',
                          marginBottom: 3,
                        }}
                      />
                    )}
                  </IconButton>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AdminSupportPage;
