import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './HomePage.scss';
import { gql, useMutation, useQuery } from '@apollo/client';
import ReviewListItem from '../../components/ListItem/ReviewListItem';
import { URLConfig, webUrl } from '../../utils/config';

const GET_ALL_TESTIMONIALS = gql`
  query GetAllTestimonialsByPage($input: GetTestimonialsByPageInput!) {
    getAllTestimonialsByPage(input: $input) {
      _id
      message
      isVisible
      rating
      createdAt
      added_by {
        name
        total_earnings
      }
    }
  }
`;

const HomePage: React.FC<{}> = (props) => {
  const [page_number, setPage_number] = useState(1);

  const navigate = useNavigate();

  const {
    data: testimonials_data,
    error: testimonials_dataError,
    loading: testimonials_dataLoading,
    refetch: testimonials_dataRefectch,
  } = useQuery(GET_ALL_TESTIMONIALS, {
    variables: {
      input: {
        page: page_number,
        item_per_page: 3,
      },
    },
  });

  const reviews = testimonials_data?.getAllTestimonialsByPage || [];

  return (
    <div className='home_page_content_wrapper'>
      <div className='home_enquiry_section'>
        <div className='home_enquiry_section_form'>
          <h1>অনলাইনে টাকা উপার্জন এখন অনেক সহজ !</h1>

          <p>
            আপনাকেই পছন্দ করা হয়েছে এই প্লাটফর্মে অবদান রেখে নিজের উপার্জন কে
            একটু বাড়িয়ে নিতে। আপনি খুব সহজে ঘরে বসেই আপনার মোবাইলের মাধ্যমে
            উপার্জন করতে পারেন হাজার হাজার টাকা। এর জন্য কিছুই প্রয়োজন নেই।
            আপনার জীবনের গল্প লিখে, কয়েন মাইনিং করে, গল্প পড়ে, ভিডিও দেখে ও গেম
            খেলেই উপার্জন করে নিন এই টাকা। কাজ শিখতে এবং শুরু করতে ডাউনলোড করুন
            আমাদের মোবাইল এপ। ডাউনলোড এর আগে পড়ে নিন{' '}
            <a href={`${webUrl}/how-to-earn`}>এখানে</a>
          </p>

          <img
            onClick={() => {
              window.open(URLConfig.appDownloadUrl, '_blank');
            }}
            style={{
              cursor: 'pointer',
            }}
            src='/images/googleplay.png'
            alt='Bus'
          />
        </div>
        <div className='home_enquiry_section_ad'>
          <img
            className='bus_img'
            style={{
              cursor: 'pointer',
            }}
            src='/images/earn_bg.png'
            alt='Bus'
          />
        </div>
      </div>

      <div className='home_testimonial_section'>
        <h2>আমাদের ব্যাবহার কারীদের রিভিউ</h2>
        {/* WILL CHAGE TO CUSTOMER REVIEW TO COMPANY */}
        <div className='home_testimonial_section_items'>
          {reviews &&
            reviews?.map((rv: any) => {
              return (
                <ReviewListItem
                  _id={rv._id}
                  key={rv._id}
                  createdAt={rv.createdAt}
                  rating={rv.rating}
                  message={rv.message}
                  review_by={{
                    name: rv.added_by.name,
                    total_earnings: rv.added_by.total_earnings,
                  }}
                />
              );
            })}
        </div>
        <div className='home_testimonial_section_action'>
          <input
            type='submit'
            value='আরো দেখুন'
            onClick={() => {
              navigate('/reviews');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
