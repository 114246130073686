import React from 'react';
import './PrivacyPageApp.scss';

const PrivacyPageApp: React.FC<{}> = (props) => {
  return (
    <>
      <h3>মোবাইলের মাধ্যমে উপার্জনের জন্য গোপনীয়তা নীতি</h3>

      <p>
        Earn By Mobile-এ, earnbymobile.site থেকে অ্যাক্সেসযোগ্য, আমাদের অন্যতম
        প্রধান অগ্রাধিকার হল আমাদের দর্শকদের গোপনীয়তা। এই গোপনীয়তা নীতি নথি
        ফুলি দ্বারা সংগৃহীত এবং রেকর্ড করা তথ্যের প্রকার রয়েছে বুক করা এবং
        কিভাবে আমরা এটি ব্যবহার.
      </p>

      <p>
        আপনার যদি অতিরিক্ত প্রশ্ন থাকে বা আমাদের সম্পর্কে আরও তথ্যের প্রয়োজন
        হয় গোপনীয়তা নীতি, ইমেল দ্বারা আমাদের সাথে যোগাযোগ করতে দ্বিধা করবেন না
        <strong> earnbymobile.uk@gmail.com</strong> বা WhatsApp-এ
        <strong> +44 7915610027</strong>
      </p>

      <br />

      <p>
        এই গোপনীয়তা নীতি শুধুমাত্র আমাদের অনলাইন কার্যকলাপের জন্য প্রযোজ্য এবং
        বৈধ আমাদের ওয়েবসাইট এবং মোবাইল অ্যাপের দর্শকদের জন্য যে তথ্য তারা
        শেয়ার করেছে এবং/অথবা Earn By Mobile-এ সংগ্রহ করেছে। এই অফলাইনে বা এর
        মাধ্যমে সংগ্রহ করা কোনো তথ্যের ক্ষেত্রে নীতি প্রযোজ্য নয় এই ওয়েবসাইট
        ছাড়া অন্য চ্যানেল।
      </p>

      <h2>সম্মতি</h2>

      <p>
        আমাদের ওয়েবসাইট এবং মোবাইল অ্যাপ ব্যবহার করে, আপনি এতদ্বারা আমাদের
        গোপনীয়তায় সম্মত হন নীতি এবং তার শর্তাবলী সম্মত.
      </p>

      <h2>তথ্য আমরা সংগ্রহ করি</h2>

      <p>
        আপনাকে যে ব্যক্তিগত তথ্য প্রদান করতে বলা হয়েছে এবং কারণ কেন আপনাকে এটি
        প্রদান করতে বলা হয়েছে, বিন্দুতে আপনাকে পরিষ্কার করা হবে আমরা আপনাকে
        আপনার ব্যক্তিগত তথ্য প্রদান করতে বলি।
      </p>
      <br />
      <p>
        আপনি যদি আমাদের সাথে সরাসরি যোগাযোগ করেন, আমরা সম্পর্কে অতিরিক্ত তথ্য
        পেতে পারি আপনি যেমন আপনার নাম, ইমেল ঠিকানা, ফোন নম্বর, এর বিষয়বস্তু
        বার্তা এবং/অথবা সংযুক্তি আপনি আমাদের পাঠাতে পারেন, এবং অন্য কোনো তথ্য
        আপনি প্রদান করতে পারেন.
      </p>
      <br />
      <p>
        আপনি যখন একটি অ্যাকাউন্টের জন্য নিবন্ধন করেন, তখন আমরা আপনার যোগাযোগের
        জন্য জিজ্ঞাসা করতে পারি নাম, কোম্পানির নাম, ঠিকানা, ইমেলের মতো আইটেম সহ
        তথ্য ঠিকানা, এবং টেলিফোন নম্বর, কোম্পানির নাম, আপনার গাড়ির ছবি, গাড়ির
        ধরন, আপনি Google ব্যবহার করে যে পরিষেবাগুলি প্রদান করেন মানচিত্র আপনি
        যদি গ্রাহক হিসাবে ওয়েবসাইট ব্যবহার করছেন, তাহলে আপনাকে প্রদান করতে হবে
        আপনার ইমেল, ফোন নম্বর এবং নাম যেটির দ্বারা আমরা মূল্য উদ্ধৃতি পাঠাই
        আপনার ইমেল এবং কোম্পানি আপনার ফোন নম্বর দিয়ে আপনার সাথে যোগাযোগ করে।
        আমরাও এখান থেকে পিকআপ লোকেশন গন্তব্যের মতো আপনার ভ্রমণের তথ্য সংগ্রহ
        করুন google মানচিত্র যা আমাদের সমস্ত কোম্পানিকে বিজ্ঞপ্তি পাঠাতে সাহায্য
        করে আপনার এলাকায় কভার পরিষেবা।
      </p>

      <h2>আমরা কীভাবে আপনার তথ্য ব্যবহার করি</h2>

      <p>
        আমরা যে তথ্য সংগ্রহ করি তা বিভিন্ন উপায়ে ব্যবহার করি, যার মধ্যে রয়েছে:
      </p>

      <ul>
        <li>আমাদের ওয়েবসাইট প্রদান, পরিচালনা এবং রক্ষণাবেক্ষণ করুন</li>
        <li>আমাদের মোবাইল অ্যাপ প্রদান, পরিচালনা এবং রক্ষণাবেক্ষণ করুন</li>
        <li>
          আমাদের ওয়েবসাইট উন্নত করুন, ব্যক্তিগতকৃত করুন এবং প্রসারিত করুন
        </li>
        <li>
          আপনি কীভাবে আমাদের ওয়েবসাইট ব্যবহার করেন তা বুঝুন এবং বিশ্লেষণ করুন
        </li>
        <li>নতুন পণ্য, পরিষেবা, বৈশিষ্ট্য এবং কার্যকারিতা বিকাশ করুন</li>
        <li>
          সরাসরি বা আমাদের অংশীদারদের একজনের মাধ্যমে আপনার সাথে যোগাযোগ করুন,
          গ্রাহক পরিষেবা সহ, আপনাকে আপডেট এবং অন্যান্য প্রদানের জন্য ওয়েবসাইট
          সম্পর্কিত তথ্য, এবং বিপণন এবং প্রচারের জন্য উদ্দেশ্য
        </li>
        <li>আপনাকে ইমেল পাঠান</li>
        <li>জালিয়াতি খুঁজুন এবং প্রতিরোধ করুন</li>
      </ul>

      <h2>লগ ফাইলগুলি</h2>

      <p>
        মোবাইল দ্বারা আয় করুন লগ ফাইল ব্যবহার করার একটি আদর্শ পদ্ধতি অনুসরণ
        করে। এইগুলো ফাইল ভিজিটর লগ লগ যখন তারা ওয়েবসাইট পরিদর্শন. সব হোস্টিং
        কোম্পানি করে এটি এবং হোস্টিং পরিষেবার বিশ্লেষণের একটি অংশ। তথ্য লগ ফাইল
        দ্বারা সংগৃহীত ইন্টারনেট প্রোটোকল (আইপি) ঠিকানা, ব্রাউজার অন্তর্ভুক্ত
        প্রকার, ইন্টারনেট সার্ভিস প্রোভাইডার (ISP), তারিখ এবং সময় স্ট্যাম্প,
        রেফারিং/প্রস্থান পৃষ্ঠা, এবং সম্ভবত ক্লিকের সংখ্যা। এগুলো নয়
        ব্যক্তিগতভাবে শনাক্ত করা যায় এমন কোনো তথ্যের সাথে সংযুক্ত। উদ্দেশ্য
        তথ্যের প্রবণতা বিশ্লেষণ, সাইট পরিচালনার জন্য, ওয়েবসাইটে ব্যবহারকারীদের
        গতিবিধি ট্র্যাক করা, এবং জনসংখ্যা সংগ্রহ করা তথ্য
      </p>

      <h2>Google DoubleClick DART কুকি</h2>

      <p>
        Google আমাদের সাইটে তৃতীয় পক্ষের একজন বিক্রেতা। এটি কুকিজও ব্যবহার করে,
        আমাদের সাইটের দর্শকদের উপর ভিত্তি করে বিজ্ঞাপন পরিবেশন করার জন্য DART
        কুকিজ নামে পরিচিত ইন্টারনেটে www.website.com এবং অন্যান্য সাইটগুলিতে
        তাদের পরিদর্শন। যাহোক, ভিজিটর ভিজিট করে DART কুকিজ ব্যবহার প্রত্যাখ্যান
        করতে পারেন Google বিজ্ঞাপন এবং বিষয়বস্তু নেটওয়ার্ক নিম্নলিখিত URL-এ
        গোপনীয়তা নীতি{' '}
        <a
          href='https://policies.google.com/technologies/ads'
          style={{ color: 'green' }}>
          https://policies.google.com/technologies/ads
        </a>
      </p>

      <h2>বিজ্ঞাপন অংশীদারদের গোপনীয়তা নীতি</h2>

      <p>
        প্রতিটির জন্য গোপনীয়তা নীতি খুঁজে পেতে আপনি এই তালিকার সাথে পরামর্শ
        করতে পারেন Earn By Mobile এর বিজ্ঞাপন অংশীদার।
      </p>

      <p>
        তৃতীয় পক্ষের বিজ্ঞাপন সার্ভার বা বিজ্ঞাপন নেটওয়ার্ক কুকিজের মতো
        প্রযুক্তি ব্যবহার করে, JavaScript, বা ওয়েব বীকন যা তাদের নিজ নিজ
        ক্ষেত্রে ব্যবহৃত হয় Earn By Mobile-এ প্রদর্শিত বিজ্ঞাপন এবং লিঙ্কগুলি
        পাঠানো হয় সরাসরি ব্যবহারকারীদের ব্রাউজারে। তারা স্বয়ংক্রিয়ভাবে আপনার
        আইপি ঠিকানা গ্রহণ করে যখন এই ঘটে। এই প্রযুক্তিগুলি পরিমাপ করতে ব্যবহৃত
        হয় তাদের বিজ্ঞাপন প্রচারের কার্যকারিতা এবং/অথবা ব্যক্তিগতকৃত করতে
        বিজ্ঞাপনের বিষয়বস্তু যা আপনি দেখেন এমন ওয়েবসাইটগুলিতে।
      </p>

      <p>
        মনে রাখবেন যে মোবাইল দ্বারা উপার্জন করুন এই কুকিগুলিতে কোনও অ্যাক্সেস বা
        নিয়ন্ত্রণ নেই৷ যেগুলি তৃতীয় পক্ষের বিজ্ঞাপনদাতাদের দ্বারা ব্যবহৃত হয়৷
      </p>

      <h2>তৃতীয় পক্ষের গোপনীয়তা নীতি</h2>

      <p>
        Earn By Mobile এর গোপনীয়তা নীতি অন্য বিজ্ঞাপনদাতাদের জন্য প্রযোজ্য নয়
        বা ওয়েবসাইট এইভাবে, আমরা আপনাকে সংশ্লিষ্ট গোপনীয়তার সাথে পরামর্শ করার
        পরামর্শ দিচ্ছি আরো বিস্তারিত তথ্যের জন্য এই তৃতীয় পক্ষের বিজ্ঞাপন
        সার্ভারের নীতি. এতে তাদের অভ্যাস এবং কীভাবে অপ্ট-আউট করতে হবে সে
        সম্পর্কে নির্দেশাবলী অন্তর্ভুক্ত থাকতে পারে নির্দিষ্ট বিকল্প।
      </p>

      <p>
        আপনি আপনার ব্যক্তিগত ব্রাউজারের মাধ্যমে কুকিজ নিষ্ক্রিয় করতে বেছে নিতে
        পারেন বিকল্প সঙ্গে কুকি ব্যবস্থাপনা সম্পর্কে আরো বিস্তারিত তথ্য জানতে
        নির্দিষ্ট ওয়েব ব্রাউজারে, এটি ব্রাউজারে পাওয়া যাবে' নিজ নিজ ওয়েবসাইট
      </p>

      <h2>CCPA গোপনীয়তা অধিকার (আমার ব্যক্তিগত তথ্য বিক্রি করবেন না)</h2>

      <p>
        CCPA-এর অধীনে, অন্যান্য অধিকারের মধ্যে, ক্যালিফোর্নিয়ার গ্রাহকদের
        অধিকার রয়েছে৷ প্রতি:
      </p>
      <p>
        অনুরোধ করুন যে একটি ব্যবসা যে একটি গ্রাহকের ব্যক্তিগত তথ্য সংগ্রহ করে
        ব্যক্তিগত তথ্যের বিভাগ এবং নির্দিষ্ট অংশগুলি প্রকাশ করুন যে ক ব্যবসা
        ভোক্তাদের সম্পর্কে সংগ্রহ করেছে.
      </p>
      <p>
        অনুরোধ করুন যে একটি ব্যবসা ভোক্তা সম্পর্কে যে কোনো ব্যক্তিগত তথ্য মুছে
        ফেলুন একটি ব্যবসা সংগ্রহ করেছে।
      </p>
      <p>
        অনুরোধ করুন যে একটি ব্যবসা যে একটি ভোক্তাদের ব্যক্তিগত তথ্য বিক্রি করে,
        বিক্রি না ভোক্তার ব্যক্তিগত তথ্য।
      </p>
      <p>
        আপনি যদি একটি অনুরোধ করেন, আমাদের কাছে আপনাকে প্রতিক্রিয়া জানাতে এক মাস
        সময় আছে। আপনি কি যদি এই অধিকারগুলির যেকোনো একটি ব্যবহার করতে চান,
        অনুগ্রহ করে আমাদের সাথে যোগাযোগ করুন।
      </p>

      <h2>GDPR ডেটা সুরক্ষা অধিকার</h2>

      <p>
        আমরা নিশ্চিত করতে চাই যে আপনি আপনার সমস্ত ডেটা সম্পর্কে সম্পূর্ণ সচেতন
        সুরক্ষা অধিকার। প্রতিটি ব্যবহারকারীর নিম্নলিখিতগুলি পাওয়ার অধিকার
        রয়েছে:
      </p>
      <p>
        অ্যাক্সেস করার অধিকার - আপনার কাছে আপনার অনুলিপি অনুরোধ করার অধিকার
        রয়েছে ব্যক্তিগত তথ্য. আমরা এই পরিষেবার জন্য আপনাকে একটি ছোট ফি নিতে
        পারি।
      </p>
      <p>
        সংশোধন করার অধিকার - আপনার কাছে আমাদের অনুরোধ করার অধিকার রয়েছে আপনি
        বিশ্বাস করেন যে কোনো তথ্য ভুল সংশোধন করুন. আপনি এছাড়াও আছে অনুরোধ করার
        অধিকার যাতে আমরা আপনার বিশ্বাস করা তথ্যটি সম্পূর্ণ করি অসম্পূর্ণ
      </p>
      <p>
        মুছে ফেলার অধিকার - আপনার কাছে অনুরোধ করার অধিকার আছে যে আমরা আপনার মুছে
        ফেলি ব্যক্তিগত তথ্য, কিছু শর্তের অধীনে।
      </p>
      <p>
        প্রক্রিয়াকরণ সীমাবদ্ধ করার অধিকার - আপনার কাছে আমাদের অনুরোধ করার
        অধিকার রয়েছে কিছু শর্তের অধীনে আপনার ব্যক্তিগত ডেটা প্রক্রিয়াকরণ
        সীমাবদ্ধ করুন।
      </p>
      <p>
        প্রক্রিয়াকরণে আপত্তি করার অধিকার - আমাদের আপত্তি করার অধিকার আপনার আছে
        আপনার ব্যক্তিগত তথ্য প্রক্রিয়াকরণ, কিছু শর্তের অধীনে।
      </p>
      <p>
        ডেটা বহনযোগ্যতার অধিকার - আপনার কাছে আমাদের অনুরোধ করার অধিকার রয়েছে
        আমরা যে ডেটা সংগ্রহ করেছি তা অন্য সংস্থায় স্থানান্তরিত করে, বা কিছু
        শর্তে সরাসরি আপনার কাছে।
      </p>
      <p>
        আপনি যদি একটি অনুরোধ করেন, আমাদের কাছে আপনাকে প্রতিক্রিয়া জানাতে এক মাস
        সময় আছে। আপনি কি যদি এই অধিকারগুলির যেকোনো একটি ব্যবহার করতে চান,
        অনুগ্রহ করে আমাদের সাথে যোগাযোগ করুন।
      </p>

      <h2>শিশুদের তথ্য</h2>

      <p>
        আমাদের অগ্রাধিকারের আরেকটি অংশ হল শিশুদের জন্য সুরক্ষা যোগ করা ইন্টারনেট
        ব্যবহার করে. আমরা পিতামাতা এবং অভিভাবকদের পালন করতে উত্সাহিত করি,
        অংশগ্রহণ, এবং/অথবা তাদের অনলাইন কার্যকলাপ নিরীক্ষণ এবং গাইড.
      </p>

      <p>
        Earn By Mobile জেনেশুনে কোনো ব্যক্তিগত শনাক্তযোগ্য সংগ্রহ করে না 13
        বছরের কম বয়সী শিশুদের কাছ থেকে তথ্য। আপনি যদি মনে করেন যে আপনার শিশু
        আমাদের ওয়েবসাইটে এই ধরনের তথ্য প্রদান করেছে, আমরা দৃঢ়ভাবে আপনাকে
        অবিলম্বে আমাদের সাথে যোগাযোগ করতে উত্সাহিত করুন এবং আমরা আমাদের
        সর্বোত্তম প্রচেষ্টা করব অবিলম্বে আমাদের রেকর্ড থেকে এই ধরনের তথ্য মুছে
        ফেলার জন্য.
      </p>

      <h2>এই গোপনীয়তা নীতিতে পরিবর্তন</h2>

      <p>
        আমরা সময়ে সময়ে আমাদের গোপনীয়তা নীতি আপডেট করতে পারি। সুতরাং, আমরা
        আপনাকে পরামর্শ দিই যেকোনো পরিবর্তনের জন্য পর্যায়ক্রমে এই পৃষ্ঠার
        প্রশংসা করতে। আমরা অবহিত করব আপনি এই পৃষ্ঠায় নতুন গোপনীয়তা নীতি পোস্ট
        করে কোনো পরিবর্তন. এইগুলো পরিবর্তনগুলি এই পৃষ্ঠায় পোস্ট করার পরে
        অবিলম্বে কার্যকর হয়৷
      </p>

      <p>
        আমাদের গোপনীয়তা নীতি এর সাহায্যে তৈরি করা হয়েছিল
        <a href='https://www.termsfeed.com/privacy-policy-generator/'>
          গোপনীয়তা নীতি জেনারেটর
        </a>
        .
      </p>

      <h2>আমাদের সাথে যোগাযোগ করুন</h2>

      <p>
        আমাদের গোপনীয়তা নীতি সম্পর্কে আপনার কোন প্রশ্ন বা পরামর্শ থাকলে, করুন
        ইমেল দ্বারা আমাদের সাথে যোগাযোগ করতে দ্বিধা করবেন না
        <strong> earnbymobile.uk@gmail.com</strong> বা WhatsApp-এ
        <strong> +44 7915610027</strong>
      </p>
    </>
  );
};

export default PrivacyPageApp;
