import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';
import { URLConfig } from './utils/config';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { createHttpLink, InMemoryCache } from '@apollo/client/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Colors from './constants/Colors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const cache = new InMemoryCache({});

const link = createHttpLink({
  uri: URLConfig.graphQLURL,
  credentials: 'include',
});

const client = new ApolloClient({
  link,
  cache,
  defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } },
  credentials: 'include',
});

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primaryColorLight,
      contrastText: '#fff',
    },
    secondary: {
      main: Colors.secondaryColorLight,
    },
  },

  typography: {
    fontFamily: "'Hind Siliguri', sans-serif",
    button: {
      textTransform: 'none',
      boxShadow: 'none',
    },
    allVariants: {
      fontFamily: "'Hind Siliguri', sans-serif",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <App />
              </LocalizationProvider>
            </ThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
