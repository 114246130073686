import moment from 'moment';
import { labelsRating } from './data';

export const beautifyString = (inputString: string) => {
  // Remove the leading slash and split the string into words
  const words = inputString.replace(/^\//, '').split('-');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words back together with spaces
  const beautifiedString = capitalizedWords.join(' ');

  return beautifiedString;
};

export const getLabelText = (value: number) => {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labelsRating[value]}`;
};

export const formatString = (slug: string) => {
  if (!slug) {
    return '';
  }

  const formattedSlug = slug
    .replace(/[-_]+/g, ' ')
    .replace(
      /\b(?!and\b)\w+/g,
      (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase()
    )
    .replace(/And/g, 'and');

  return formattedSlug;
};

export const getRatingLabel = (value: number): string => {
  if (value <= 0.5) {
    return labelsRating[0.5];
  } else if (value <= 1) {
    return labelsRating[1];
  } else if (value <= 1.5) {
    return labelsRating[1.5];
  } else if (value <= 2) {
    return labelsRating[2];
  } else if (value <= 2.5) {
    return labelsRating[2.5];
  } else if (value <= 3) {
    return labelsRating[3];
  } else if (value <= 3.5) {
    return labelsRating[3.5];
  } else if (value <= 4) {
    return labelsRating[4];
  } else if (value <= 4.5) {
    return labelsRating[4.5];
  } else if (value <= 5) {
    return labelsRating[5];
  }

  // Default case if the value is out of the specified range
  return 'Unknown';
};

export const getNameAvatar = (name: string) => {
  const avtrArr = name?.match(/\b(\w)/g)?.slice(0, 2);
  if (!avtrArr) return name;
  const avtr = avtrArr.slice(0, 2).join('').toUpperCase();
  return avtr;
};

export const formatMessages = (
  messages: { _id: string; content: string; sender: string; sentAt: string }[]
) => {
  // Function to format date as "DD MMM, YYYY"
  const formatDate = (dateString: string) => {
    return moment(dateString).format('DD MMM, YYYY');
  };

  // Group messages by date using Map()
  const messageMap = new Map<
    string,
    { _id: string; content: string; sender: string; sentAt: string }[]
  >();

  messages.forEach((message) => {
    const formattedDate = formatDate(message.sentAt);
    if (!messageMap.has(formattedDate)) {
      messageMap.set(formattedDate, []);
    }

    messageMap.get(formattedDate)?.push(message);
  });

  const formattedmessages = [];
  for (const [date, messages] of messageMap) {
    formattedmessages.push(date);
    formattedmessages.push(...messages);
  }

  return formattedmessages;
};

export const getAmountWithCurrency = (value: number, isCent?: boolean) => {
  return new Intl.NumberFormat('bn-BD', {
    style: 'currency',
    currency: 'BDT',
  }).format(value / (isCent ? 100 : 1));
};

export const formatNumberInBangla = (value: number) => {
  return new Intl.NumberFormat('bn-BD').format(value);
};

export const maskString = (input: string): string => {
  const words = input.split(' ');

  const maskedWords = words.map((word) => {
    if (word.length <= 2) {
      return word;
    }

    const firstChar = word[0];
    const lastChar = word[word.length - 1];
    const maskedChars = '*'.repeat(word.length - 2);

    return `${firstChar}${maskedChars}${lastChar}`;
  });

  return maskedWords.join(' ');
};
