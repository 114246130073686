import './CustomButton.scss';

const CustomButton: React.FC<{
  title: string;
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}> = ({ title, onClick, isLoading = false, isDisabled = false }) => {
  return (
    <div className='custom_button'>
      <input
        type='submit'
        value={isLoading ? 'Please wait...' : title}
        onClick={onClick}
        disabled={isDisabled}
      />
    </div>
  );
};

export default CustomButton;
